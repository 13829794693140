/* eslint-disable jsx-a11y/anchor-is-valid */
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import ScrollToTop from "react-scroll-to-top";
import HomeTwo from "./pages/HomeTwo";
import About from "./pages/About";
import Service from "./pages/Service";
// import ServiceDetails from "./pages/ServiceDetails";
import ProduitDetails from "./pages/ProduitDetails";
import Blog from "./pages/Blog";
import Equipe from "./pages/Equipe";
import OffreEmploi from "./pages/Emploi";
import RelationInvestisseurs from "./pages/RelationInvestisseurs";
import PostulerOffre from "./pages/PostulerOffre";
import Offre from "./pages/Offre";
import Certification from "./pages/Certification";
import Prix from "./pages/Prix";
import BlogDetails from "./pages/BlogDetails";
import Faq from "./pages/Faq";
import Contact from "./pages/Contact";
import NotFound from "./components/NotFound";
import RouteScrollToTop from "./elements/RouteScrollToTop";
import Produits from "./pages/Produits";
function App() {
  useEffect(() => {
    AOS.init({
      offset: 0,
      easing: "ease",
      once: true,
    });
    AOS.refresh();
  }, []);
  return (
    // eslint-disable-next-line
    <BrowserRouter>
      <RouteScrollToTop />
      <Routes>
        <Route exact path="/" element={<HomeTwo />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/produits" element={<Produits />} />
        <Route exact path="/service" element={<Service />} />
        <Route exact path="/postuler/:id" element={<PostulerOffre />} />
        {/* <Route exact path="/service-details" element={<ServiceDetails />} /> */}
        <Route exact path="/produit-details/:id" element={<ProduitDetails />} />
        <Route exact path="/equipe" element={<Equipe />} />
        <Route exact path="/offre" element={<Offre />} />
        <Route exact path="/certification" element={<Certification />} />
        <Route
          exact
          path="/relation-investisseurs"
          element={<RelationInvestisseurs />}
        />
        <Route exact path="/offre-d'emploi" element={<OffreEmploi />} />
        <Route exact path="/prix-distinction" element={<Prix />} />
        <Route exact path="/blog" element={<Blog />} />
        <Route exact path="/blog-details/:id" element={<BlogDetails />} />
        <Route exact path="/faq" element={<Faq />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route path="/*" element={<NotFound />} />
        {/* <Route>
          {" "}
          <NotFound />{" "}
        </Route> */}
      </Routes>
      <ScrollToTop smooth color="#FA4318" />
    </BrowserRouter>
  );
}

export default App;
