import React, { Fragment, Suspense } from "react";
import Preloader from "../elements/Preloader";
const AboutOne = React.lazy(() => import("../components/AboutOne"));
const Breadcrumb = React.lazy(() => import("../components/Breadcrumb"));
const CounterTwo = React.lazy(() => import("../components/CounterTwo"));
const FooterBottomOne = React.lazy(() =>
  import("../components/FooterBottomOne")
);
const FooterTwo = React.lazy(() => import("../components/FooterTwo"));
const NavbarTwo = React.lazy(() => import("../components/NavbarTwo"));
const PartnerTwo = React.lazy(() => import("../components/PartnerTwo"));
// const SkillOne = React.lazy(() => import("../components/SkillOne"));
const TeamTwo = React.lazy(() => import("../components/TeamTwo"));
const TestimonialTwo = React.lazy(() => import("../components/TestimonialTwo"));
const VideoAreaTwo = React.lazy(() => import("../components/VideoAreaTwo"));
const SearchPopup = React.lazy(() => import("../elements/SearchPopup"));
const About = () => {
  return (
    <>
      <Fragment>
        <Suspense fallback={<Preloader />}>
          {/* Search Popup */}
          <SearchPopup />

          {/* Navbar One */}
          <NavbarTwo />

          {/* Breadcrumb */}
          <Breadcrumb
            title="Plus d'infos sur nous"
            pageName="A propos"
            curentPageName="A propos"
          />

          {/* About One */}
          <div className="pd-top-120 pd-bottom-120">
            <AboutOne />
          </div>

          {/* Counter One */}

          <div className="fact-area mb-5" style={{ background: "#f9f9f9" }}>
            <CounterTwo />
          </div>

          {/* Skill One */}
          {/* <SkillOne /> */}

          {/* Video Area One */}
          <VideoAreaTwo />

          {/* Team One */}
          <div className="pd-bottom-80 mt-5">
            <TeamTwo />
          </div>

          {/* Testimonial Two */}
          <TestimonialTwo />

          {/* Partner One */}
          <PartnerTwo />

          {/* Footer One */}
          <FooterTwo />

          {/* Footer Bottom One */}
          <FooterBottomOne />
        </Suspense>
      </Fragment>
    </>
  );
};

export default About;
