import React, { Fragment, Suspense } from "react";
import Preloader from "../elements/Preloader";
const AboutTwo = React.lazy(() => import("../components/AboutTwo"));
const BannerTwo = React.lazy(() => import("../components/BannerTwo"));
// const BlogTwo = React.lazy(() => import("../components/BlogTwo"));
const CounterTwo = React.lazy(() => import("../components/CounterTwo"));
// const FaqOne = React.lazy(() => import("../components/FaqOne"));
const FooterBottomOne = React.lazy(() =>
  import("../components/FooterBottomOne")
);
const FooterTwo = React.lazy(() => import("../components/FooterTwo"));
const NavbarTwo = React.lazy(() => import("../components/NavbarTwo"));
const PartnerTwo = React.lazy(() => import("../components/PartnerTwo"));
// const SigninPopup = React.lazy(() => import("../components/SigninPopup"));
const VideoAreaOne = React.lazy(() => import("../components/VideoAreaOne"));
const SearchPopup = React.lazy(() => import("../elements/SearchPopup"));
// const SignInPopUp = React.lazy(() => import("../elements/SignInPopUp"));
const HomeTwo = () => {
  return (
    <>
      <Fragment>
        <Suspense fallback={<Preloader />}>
          {/* Search Popup */}
          <SearchPopup />
          <NavbarTwo />
          {/* <SigninPopup /> */}

          {/* <LayoutOne activeClass="active" wrapperClass="home-page-1">
        {/* <main>
          <BannerOne />
          <BrandSlider />
          <WorkingProgressOne sectionSpace="sec-ptb-160" title={titleOne} />
          <FeaturesNav />
          <FeatureOne sectionSpace="sec-ptb-160" />
          <ServiceSliderOne sectionSpace="sec-ptb-160 pt-0" />
          <TestimonialSliderOne
            sectionSpace="sec-ptb-160"
            bgColor="bg-light-gray"
          />
          <FanFact />
          <Pricing pricingBg="assets/images/pricing/sec-bg-1.jpg" />
          <AppSectionOne />
          <FaqSectionOne />
          <BlogSlider sectionSpace="sec-ptb-160" />
        </main> 
      </LayoutOne> */}

          <BannerTwo />

          {/* About Two */}
          <AboutTwo />

          {/* Request Quote One */}
          {/* <RequestQuoteOne /> */}

          {/* Counter Two */}
          <CounterTwo />
          <br />
          <br />
          <br />
          <br />
          <br />
          {/* Video Area One */}
          <VideoAreaOne />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          {/* Partner Two */}
          <PartnerTwo />
          {/* Banner Two */}

          {/* Faq One */}
          {/* <div className="faq-area pd-top-120">
            <FaqOne />
          </div> */}

          {/* Blog Two */}
          {/* <BlogTwo /> */}
          {/* Footer Two */}
          <FooterTwo />

          {/* Footer Bottom One */}
          <FooterBottomOne />
        </Suspense>
      </Fragment>
    </>
  );
};

export default HomeTwo;
