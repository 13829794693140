import React from "react";
import { Link } from "react-router-dom";
// import NotFoundImage from "./path/to/your/image.jpg"; // Remplacez par le chemin de votre image

const NotFound = () => {
  const notFoundStyle = {
    background: `url("/assets/img/bg/bg_notfound.jpeg") center/cover no-repeat`,
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: "#fff",
    textAlign: "center",
  };

  const headingStyle = {
    fontSize: "4rem",
    color: "white",
    marginBottom: "20px",
  };

  const leadStyle = {
    color: "white",
    fontSize: "1.5rem",
    marginBottom: "30px",
  };

  return (
    <div style={notFoundStyle}>
      <h1 style={headingStyle}>404</h1>
      <p style={leadStyle}>La page que vous recherchez est introuvable.</p>
      <Link to="/" className="btn btn-base">
        Retour à l'accueil
      </Link>
    </div>
  );
};

export default NotFound;
