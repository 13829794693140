import React, { Fragment, Suspense } from "react";
import Preloader from "../elements/Preloader";
const PostulerOffreView = React.lazy(() =>
  import("../components/PostulerOffreView")
);
const FooterBottomOne = React.lazy(() =>
  import("../components/FooterBottomOne")
);
const Breadcrumb = React.lazy(() => import("../components/Breadcrumb"));
const FooterTwo = React.lazy(() => import("../components/FooterTwo"));
const NavbarTwo = React.lazy(() => import("../components/NavbarTwo"));
const SearchPopup = React.lazy(() => import("../elements/SearchPopup"));
const PostulerOffre = () => {
  return (
    <>
      <Fragment>
        <Suspense fallback={<Preloader />}>
          {/* Search Popup */}
          <SearchPopup />

          {/* Navbar One */}
          <NavbarTwo />

          <Breadcrumb
            title="Postuler à nos offre d'emploi"
            pageName="Offre d'emploi"
            curentPageName="Offre d'emploi"
          />

          {/* Blog View */}
          <PostulerOffreView />

          {/* Footer One */}
          <FooterTwo />

          {/* Footer Bottom One */}
          <FooterBottomOne />
        </Suspense>
      </Fragment>
    </>
  );
};

export default PostulerOffre;
