import React, { Fragment, Suspense } from "react";
import Preloader from "../elements/Preloader";
const Breadcrumb = React.lazy(() => import("../components/Breadcrumb"));
const FooterBottomOne = React.lazy(() =>
  import("../components/FooterBottomOne")
);
const FooterTwo = React.lazy(() => import("../components/FooterTwo"));
const NavbarTwo = React.lazy(() => import("../components/NavbarTwo"));
const ProduitDetailsInner = React.lazy(() =>
  import("../components/ProduitDetailsInner")
);
const SearchPopup = React.lazy(() => import("../elements/SearchPopup"));
const ProduitDetails = () => {
  return (
    <>
      <Fragment>
        <Suspense fallback={<Preloader />}>
          {/* Search Popup */}
          <SearchPopup />

          {/* Navbar One */}
          <NavbarTwo />
          {/* Breadcrumb */}
          <Breadcrumb
            title="Détails de produit"
            pageName="Détails"
            curentPageName="Détails"
          />

          {/* Service Details Inner */}
          <ProduitDetailsInner />

          {/* Footer One */}
          <FooterTwo />

          {/* Footer Bottom One */}
          <FooterBottomOne />
        </Suspense>{" "}
      </Fragment>
    </>
  );
};

export default ProduitDetails;
